import "./Home.scss";
import FluusWidget from "@fluus/widget";
import allImages from "../../constants/images";

const Home = () => {
  return (

    <>
      {/* <div className="home-wrapper"> */}
      <div className="topbar">
        <img
          src={allImages.fluusLogoBeta}
          className="fluus-main-logo"
          alt="FLUUS_LOGO"
        />
      </div>
      {/* <div className="main-container"> */}
      <div className="widget-container" id="widgetContainer">
        <FluusWidget API_KEY={process.env.REACT_APP_X_API_KEY} />
      </div>
      {/* </div> */}

      {/* </div> */}
    </>
  );
};

export default Home;
